.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10vh;
    position: relative;
    box-sizing: border-box;


}
.headerContainer{
    width: 40%;
    padding: .5vw 5vw;
    margin-top: 5vw;
    border-bottom: 4px solid #2B4EA7;
    border-right: 2px solid #2B4EA7;
    border-left: 2px solid #2B4EA7;
    border-radius: 5vw;
    z-index: 3;
}
.header{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 2.5vw;
    opacity: .8;
    margin: 0 0 0 0;
    z-index: 2;
}
.rowContaiener{
    display: flex;
    margin-top: 3vw;
    z-index: 2;
    position: relative;
    overflow: hidden;
}
.textContainer{
    width: 60%;
}
.imgContainer{
    width: 40%;
}
.img{
    width: 80%;
}
.textContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.ul {
	list-style-type: none; 
	counter-reset: num;
	margin: 0 0 0 35px;
	padding: 15px 0 5px 0;  
}
.ul li {
	position: relative;	
	margin: 0 0 0 0;
	padding: 0 0 10px 0;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 1vw;
}
.ul li:before {
	content: counter(num) '.'; 
	counter-increment: num;
	display: inline-block;	
	position: absolute;
	top: 0px;
	left: -26px;
	width: 20px;    
	color: #000000;
	text-align: right;
}





@media (max-width:768px) {
    .container{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10vh;
        position: relative;
        box-sizing: border-box;
    
    }
    .headerContainer{
        padding: 2vw 12vw;
        margin-top: 10vh;
        border-radius: 10vh;
        max-width: 90%;
        width: 80%;
        box-sizing: border-box;

    }
    .header{
        font-size: 7vw;
        
    }
    .rowContaiener{
        display: flex;
        align-items: center;justify-content: center;
        flex-direction: column-reverse;
        margin-top: 3vw;
        z-index: 2;
        width: 100%;
    }
    .textContainer{
        width: 60%;

    }
    .imgContainer{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5vh;
    }
    .img{
        width: 80%;
    }
    .textContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;
        margin-top: 3vh;
    }
    .ul {
        list-style-type: none; 
        counter-reset: num;
        margin: 0 0 0 35px;
        padding: 2vw 0 2vw 0;  
    }
    .ul li {
        position: relative;	
        margin: 0 0 0 0;
        padding: 0 0 10px 0;
        font-family: var(--font-family);
        font-weight: 500;
        font-size: 4vw;
    }
    .ul li:before {
        content: counter(num) '.'; 
        counter-increment: num;
        display: inline-block;	
        position: absolute;
        top: 0px;
        left: -26px;
        width: 20px;    
        color: #000000;
        text-align: right;
    }
}