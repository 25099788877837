

.container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10vh;
}
.modalBefore{

    width: 80%;
    width: auto;
    height: auto;
    position: relative;
}
.modalWind{
    width: 60vw;
    padding: 2vw;
    border-radius: 2vw;
    background-color: white;
}
.modalWind::before {
    content: ''; /* Обязательное свойство для псевдоэлементов */
    position: absolute; /* Позиционируем псевдоэлемент */
    top: -4px; /* Сдвигаем его на 1 пиксель вверх для перекрытия границы */
    left: -4px; /* Сдвигаем его на 1 пиксель влево для перекрытия границы */
    right: -4px; /* Растягиваем его на всю ширину */
    bottom: -4px; /* Растягиваем его на всю высоту */
    z-index: -1; /* Помещаем псевдоэлемент под основной контент */
    border-radius: 2vw;
    background: linear-gradient(to bottom, #2D53B6, rgba(0, 0, 255, 0)); /* Задаем линейный градиент */
}
.header{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 1.5vw;
    text-align: center;
}
.buttonContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vw;
    margin-top: 2vw;
}
.icon{
    width: 4vw;
    transition: all .3s;
    cursor: pointer;
}
.icon:hover{
    transform: scale(103%);
}
.button{
    background-color: #31D385;
    color:white;
    font-family: var(--font-family);
    font-weight: 500;
    /* border-bottom: .4vw solid #007F42;
    border-right: .1vw solid #007F42;
    border-left: .1vw solid #007F42; */
    width: 20%;
    padding: .8vw 0;
    border-radius: 5vw;
    font-size: 1vw;
    transition: all .3s ease;
    cursor: pointer;
    border: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.button:hover{
    transform: scale(102%);

}
.rowContainer{
    display: flex;
}

@media (max-width:768px){

    .container{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10vh;
    }
    .modalBefore{
    
        width: 80%;
        width: auto;
        height: auto;
        position: relative;
    }
    .modalWind{
        width: 80vw;
        padding: 2vw;
        border-radius: 2vw;
        background-color: white;
        display: flex;
        text-align: center;
        justify-content: center;
        flex-direction: column;
    }
    .modalWind::before {
        content: ''; /* Обязательное свойство для псевдоэлементов */
        position: absolute; /* Позиционируем псевдоэлемент */
        top: -2px; /* Сдвигаем его на 1 пиксель вверх для перекрытия границы */
        left: -2px; /* Сдвигаем его на 1 пиксель влево для перекрытия границы */
        right: -2px; /* Растягиваем его на всю ширину */
        bottom: -2px; /* Растягиваем его на всю высоту */
        z-index: -1; /* Помещаем псевдоэлемент под основной контент */
        border-radius: 2vw;
        background: linear-gradient(to bottom, #2D53B6, rgba(0, 0, 255, 0)); /* Задаем линейный градиент */
    }
    .header{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 4vw;
        text-align: center;
        padding: 0 5%;
        text-align: center;
        margin-top: 5vh;
    }
    .buttonContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2vw;
        margin-top: 2vw;
    }
    .rowContainer{
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 10%;
    }
    .icon{
        width: 20vw;
        transition: all .3s;
        cursor: pointer;
    }
    .icon:hover{
        transform: scale(103%);
    }
    .button{
        background-color: #31D385;
        color:white;
        font-family: var(--font-family);
        font-weight: 500;
        /* border-bottom: .4vw solid #007F42;
        border-right: .1vw solid #007F42;
        border-left: .1vw solid #007F42; */
        width: 80%;
        padding: 2vh 0;
        border-radius: 5vh;
        font-size: 4vw;
        transition: all .3s ease;
        cursor: pointer;
        border: none;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2vh;
    }
    .button:hover{
        transform: scale(102%);
    
    }
}