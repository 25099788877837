.container{
    height: auto;
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 3vw 10vw;
    gap: 1vw ;
    box-sizing: border-box;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}
.header{
    
    color: black;
    font-size: 2vw;

    text-align: center;
    margin-top: 3vw;
    max-width: 78vw;
    z-index: 10;
    backdrop-filter: blur(1px);
    opacity: .8;
}
.header1{
    
    color: black;
    font-size: 1.7vw;

    text-align: start;
    margin-top: 3vw;
    max-width: 78vw;
    z-index: 10;
    backdrop-filter: blur(1px);
    opacity: .8;
    margin-bottom: 2vw;
}
.description{
    
    color: black;
    font-size: 1vw;
    text-align: start;
    max-width: 78vw;
    z-index: 10;
    backdrop-filter: blur(1px);
    margin: 0;
    opacity: .8;
}

@media (max-width: 768px){
    .container{
        padding: 5vh 5vw;
        gap: 1vh ;
    }
    .header{
        font-size: 7vw;
        margin-top: 1vh;
        max-width: 95%;

    }
    .header1{
        font-size: 6vw;
    }
    .description{
        font-size: 4vw;
    
    }
}