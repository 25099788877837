.container{
    width: 100%;
    height: 10vw;
    min-height: 10vw;
    border-top: 1px solid rgb(211, 211, 211);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5vw .2vw 0 0;
    box-sizing: border-box;
    background-color: #31D385;
    border-radius: 2vw 2vw 0 0;
}
.nestContainer{
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 2vw 2vw 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10vw;
    box-sizing: border-box;
}
.logo{
    height: 40%;
    width: auto;
}
.link{
    text-decoration: none;
    font-family: var(--font-family);
    font-size: .7vw;
    font-weight: 500;
    color: rgb(0, 0, 0);
    margin: 0;
}
.nav{
    display: flex;
    flex-direction: column;
    gap:.5vw;
}
@media (max-width:768px) {
    .container{
        width: 100%;
        height: auto;
        min-height: 50vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .7vh .5vh 0 .5vh;
        box-sizing: border-box;
        background-color: #31D385;
        border-radius: 2vw 2vw 0 0;
    }
    .nestContainer{
        height: 100%;
        width: 100%;
        background-color: rgb(255, 255, 255);
        border-radius: 2vw 2vw 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5vh 0;
        box-sizing: border-box;gap: 5vh;
    }
    .logo{
        height: auto;
        width: 35%;
    }
    .link{
        text-decoration: none;
        font-family: var(--font-family);
        font-size: 3vw;
        font-weight: 500;
        color: rgb(0, 0, 0);
        margin: 0;
    }
    .nav{
        display: flex;
        flex-direction: column;
        gap:1vh;
    }
}