.container{
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.625);
    display: flex;
    align-items: center;
    justify-content: center;
}
.errorText{
    color: red;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: .9vw;
}
.modalWind{
    padding: 2vw 6vw;
    width: 30%;
    background-color: white;
    border-radius: 5vw 1vw 5vw 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.input{
    border: none;
    border-bottom: 1px solid gray;
    width: 80%;
    text-align: center;
    margin-top: 1vw;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 1.1vw;
    color: black;
}
.input:focus{
    outline: none; /* Убираем контур при фокусе */
    border: none; /* Убираем границу при фокусе */
    border-bottom: 1px solid gray;
}



.button{
    background-color: #31D385;
    color:white;
    font-family: var(--font-family);
    font-weight: 700;
    border-bottom: .4vw solid #007F42;
    border-right: .1vw solid #007F42;
    border-left: .1vw solid #007F42;
    width: 100%;
    padding: .5vw 2vw;
    border-radius: 5vw;
    font-size: 1.5vw;
    margin-top: 2vw;
    transition: all .3s ease;
    cursor: pointer;
}
.button:hover{
    transform: scale(102%);
    border-bottom: .4vw solid #01a656;
    border-right: .1vw solid #01a656;
    border-left: .1vw solid #01a656;
}
.inputContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
.inputContainer span{
    color: red;
    font-size: 1vw;
    position: absolute;
    top: 45%;
    right: 2vw;
}
.faqTrigger{
    position: absolute;
    top: 2vw;
    right: 2vw;
    font-size: 1vw;
    font-family: var(--font-family);
    border: 1px solid gray;
    border-radius: 50%;
    height: 2vw;
    width: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-color: white;
    font-weight: 800;
    transition: all .3s;
    color: rgb(53, 53, 53);
}
.faqTrigger:hover{
    background-color: rgb(246, 246, 246);
    border: 1px solid rgb(211, 211, 211);
    color:black;
}
.faqModal{
    position: absolute;
    width: 60%;
    height: 50%;
    top: 12%;
    right: -62%;
    background-color: white;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 1vw 1.5vw;
    box-shadow:  0px 0px 29px 4px rgba(0, 0, 0, 0.341);
    border-radius: 5vw 1vw 5vw 0;
    font-family: var(--font-family);
    color: black;
    font-size: .8vw;
}
.privateContainer{
    display: flex;
    align-items: center;
    margin-top: 1vw;
    gap: 1vw;
}
.privateContainer span{
    font-family: var(--font-family);
    color: black;
    font-size: .9vw;
}
.link{
    font-family: var(--font-family);
    color: black;
}
.checkbox{
    width: 1.1vw;
    height: 1.1vw;
    border-radius: .1vw;
    border:1px solid gray;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.mark{
    width: 80%;
}
@media (max-width:768px) {
    .errorText{
        margin-top: 2vh;
        font-size: 2.9vw;
    }
    .faqTrigger{
        font-size:3vw;
        height: 6vw;
        width: 6vw;
    
    }
    .faqModal{
        position: absolute;
        width: 75%;
        height: 80%;
        top: 12%;
        left: 13%;
        background-color: white;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 2vw 3vw;
        text-align: center;
        box-shadow:  0px 0px 29px 4px rgba(0, 0, 0, 0.341);
        border-radius: 5vw 1vw 5vw 0;
        font-family: var(--font-family);
        color: black;
        font-size: 3vw;
    }
    .modalWind{
        width: 90%;
        box-sizing: border-box;
        padding: 5vh 3vh 3vh 3vh;
        background-color: white;
        border-radius: 5vw 1vw 5vw 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .input{
        border: none;
        border-bottom: 1px solid gray;
        width: 80%;
        text-align: center;
        margin-top: 2vh;
        font-family: var(--font-family);
        font-weight: 500;
        font-size: 4vw;
        color: black;
    }
    .input:focus{
        outline: none; /* Убираем контур при фокусе */
        border: none; /* Убираем границу при фокусе */
        border-bottom: 1px solid gray;
    }
    
    
    
    .button{
        background-color: #31D385;
        color:white;
        font-family: var(--font-family);
        font-weight: 700;
        border: none;
        border-bottom: .4vw solid #007F42;
        border-right: .1vw solid #007F42;
        border-left: .1vw solid #007F42;
    
        padding: 1vh 5vh;
        border-radius: 5vw;
        font-size:3vw;
        margin-top: 5vh;
        transition: all .3s ease;
        cursor: pointer;
    }
    .button:hover{
        transform: scale(102%);
        border-bottom: .4vw solid #01a656;
        border-right: .1vw solid #01a656;
        border-left: .1vw solid #01a656;
    }
    .privateContainer{
        display: flex;
        align-items: center;
        margin-top: 3vh;
        gap: 1vw;
    }
    .privateContainer span{
        font-family: var(--font-family);
        color: black;
        font-size: 2.5vw;
    }
    .link{
        font-family: var(--font-family);
        color: black;
    }
    .checkbox{
        width: 2.1vw;
        height: 2.1vw;
        border-radius: .1vw;
        border:1px solid gray;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .mark{
        width: 80%;
    }
}