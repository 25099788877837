.container{
    width: 100%;
    height: 4vw;
    background-color: rgb(255, 255, 255);
    box-shadow:  0px 15px 26px -10px rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    position: fixed;
}
.logo{
    height: 3vw;
    margin-left: 2vw;
    transition: all .3s;
    cursor: pointer;
}
.logo:hover{
    transform: scale(105%);
}
.nav{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vw;
    margin-right: 2vw;
}
.Link{
    font-family: var(--font-family);
    font-weight: 600;
    cursor: pointer;
    transition: all .3s;
    opacity: .8;
    font-size: 1.1vw;
    color: black;
    text-decoration: none;
}
.Link:hover{
    transform: scale(103%);
    opacity: 1;
}
.butgerTrigger,.close{
    display: none;
}
.NumberMibileLink{
    display: none;
}
.NumberLink{
    font-family: var(--font-family);
    font-weight: 600;
    cursor: pointer;
    transition: all .3s;
    opacity: .8;
    font-size: 1.7vw;
    color: black;
    text-decoration: none;
}
.NumberLink:hover{
    transform: scale(103%);
    opacity: 1;
}
@media (min-width:1480px) {
    .Link{
        font-size: .8vw;
    }
    .NumberLink{
        font-size: 1.2vw;
    }
    .logo{
        height: 2.7vw;
    }
}

@media (max-width:768px){

    .container{
        width: 100%;
        height: 9vh;
        background-color: rgb(255, 255, 255);
        box-shadow:  0px 15px 26px -10px rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 999;
        position: fixed;
    }
    .logo{
        height: 6vh;
        margin-left: 4vw;
        transition: all .3s;
        cursor: pointer;
    }
    .logo:hover{
        transform: scale(105%);
    }
    .nav{
        position: absolute;
        top: 0;
        left: -100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100vh;
        width: 100%;
        background-color: white;
        transition:  transform .3s ease-in-out;
        z-index: 100;
        gap: 5vh;
    }
    .navActive{
        transform: translateX(100%);
    }
    .Link{
        font-family: var(--font-family);
        font-size: 7vw;
        font-weight: 600;
        cursor: pointer;
        transition: all .3s;
        opacity: .8;
    }
    .NumberLink{
        font-family: var(--font-family);
        font-size: 7vw;
        font-weight: 600;
        cursor: pointer;
        transition: all .3s;
        opacity: .8;
    }
    .Link:hover{
        transform: scale(103%);
        opacity: 1;
    }
    
    .butgerTrigger{
        z-index: 9;
        display: block;
        margin-right: 4vw;
        font-size: 6vw;
        font-weight: 600;
        opacity: .8;
        font-family: var(--font-family);
        transform: rotate(90deg); /* Поворачиваем элемент на 90 градусов */
    }
    .close{
        display: block;
        position: absolute;
        top: 3vh;
        right: 3vh;
        z-index: 9999;
        font-size: 5vh;
        font-weight: 600;
        opacity: .8;
        font-family: var(--font-family);
    }
    .NumberMibileLink{
        display: block;
        font-size: 4vw;
    }

}
