.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10vh;
    position: relative;
    box-sizing: border-box;

}
.headerContainer{
    width: 40%;
    padding: .5vw 3vw;
    margin-top: 5vw;
    border-bottom: 4px solid #2B4EA7;
    border-right: 2px solid #2B4EA7;
    border-left: 2px solid #2B4EA7;
    border-radius: 5vw;
}
.header{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 2.7vw;
    opacity: .8;
    margin: 0 0 0 0;
}
.cardContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5vw;
    margin-top: 5vw;
}
.catdBefore{
    height: auto;
    width: auto;
    position: relative;
    width: 20%;
}
.card{

    height: auto;
    padding: 1vw;
    border-radius: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.active::after {
    box-shadow:  1px 0px 4px 4px rgba(238, 201, 17, 0.184);
    content: ''; /* Обязательное свойство для псевдоэлементов */
    position: absolute; /* Позиционируем псевдоэлемент */
    top: -4px; /* Сдвигаем его на 1 пиксель вверх для перекрытия границы */
    left: -4px; /* Сдвигаем его на 1 пиксель влево для перекрытия границы */
    right: -4px; /* Растягиваем его на всю ширину */
    bottom: -4px; /* Растягиваем его на всю высоту */
    z-index: -1; /* Помещаем псевдоэлемент под основной контент */
    border-radius: 2vw;
    background: linear-gradient(to bottom, #d9c21200, rgba(255, 234, 1, 0.167)); /* Задаем линейный градиент */
}
.card::before {
    
    content: ''; /* Обязательное свойство для псевдоэлементов */
    position: absolute; /* Позиционируем псевдоэлемент */
    top: -4px; /* Сдвигаем его на 1 пиксель вверх для перекрытия границы */
    left: -4px; /* Сдвигаем его на 1 пиксель влево для перекрытия границы */
    right: -4px; /* Растягиваем его на всю ширину */
    bottom: -4px; /* Растягиваем его на всю высоту */
    z-index: -1; /* Помещаем псевдоэлемент под основной контент */
    border-radius: 2vw;
    background: linear-gradient(to bottom, #2D53B6, rgba(0, 0, 255, 0)); /* Задаем линейный градиент */
}

.cardHeaderBox{
    margin-top: 2vw;
    width: 40%;
    padding: .5vw 3vw;
    border-bottom: 4px solid #2B4EA7;
    border-right: 1px solid #2B4EA7;
    border-left: 1px solid #2B4EA7;
    border-radius: 5vw;
}
.cardHeader{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 1.2vw;
}
.cardUl{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 3vw;
    margin-top: 5vw;
    max-width: 90%;
    gap: 1vw;
}
.cardLi{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 1vw;
}
.price{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 2vw;
    margin-top: 2.5vw;
}
.button{
    background-color: #31D385;
    color:white;
    font-family: var(--font-family);
    font-weight: 700;
    border-bottom: .4vw solid #007F42;
    border-right: .1vw solid #007F42;
    border-left: .1vw solid #007F42;
    width: 80%;
    padding: .5vw 0;
    border-radius: 5vw;
    font-size: 1.2vw;
    margin-top: 3vw;
    transition: all .3s ease;
    cursor: pointer;
}
.button:hover{
    transform: scale(102%);
    border-bottom: .4vw solid #01a656;
    border-right: .1vw solid #01a656;
    border-left: .1vw solid #01a656;
}


@media (max-width:768px) {
    .headerContainer{
        padding: 1vh 10vh;
        margin-top: 10vh;
        border-radius: 10vh;
        max-width: 90%;

    }
    .header{
        font-size: 7vw;
        
    }
    .cardContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7vh;
    margin-top: 10vh;
}
.catdBefore{
    height: auto;
    width: auto;
    position: relative;
    width: 85%;
}
.card{

    height: auto;
    padding: 5vh ;
    border-radius: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.active::after {
    box-shadow:  1px 0px 4px 4px rgba(238, 201, 17, 0.184);
    content: ''; /* Обязательное свойство для псевдоэлементов */
    position: absolute; /* Позиционируем псевдоэлемент */
    top: -4px; /* Сдвигаем его на 1 пиксель вверх для перекрытия границы */
    left: -4px; /* Сдвигаем его на 1 пиксель влево для перекрытия границы */
    right: -4px; /* Растягиваем его на всю ширину */
    bottom: -4px; /* Растягиваем его на всю высоту */
    z-index: -1; /* Помещаем псевдоэлемент под основной контент */
    border-radius: 10vw;
    background: linear-gradient(to bottom, #d9c21200, rgba(255, 234, 1, 0.167)); /* Задаем линейный градиент */
}
.card::before {
    
    content: ''; /* Обязательное свойство для псевдоэлементов */
    position: absolute; /* Позиционируем псевдоэлемент */
    top: -4px; /* Сдвигаем его на 1 пиксель вверх для перекрытия границы */
    left: -4px; /* Сдвигаем его на 1 пиксель влево для перекрытия границы */
    right: -4px; /* Растягиваем его на всю ширину */
    bottom: -4px; /* Растягиваем его на всю высоту */
    z-index: -1; /* Помещаем псевдоэлемент под основной контент */
    border-radius: 10vw;
    background: linear-gradient(to bottom, #2D53B6, rgba(0, 0, 255, 0)); /* Задаем линейный градиент */
}

.cardHeaderBox{
    margin-top: 2vh;
    width: 80%;
    padding: 2vh 3vh;
    border-bottom: 4px solid #2B4EA7;
    border-right: 1px solid #2B4EA7;
    border-left: 1px solid #2B4EA7;
    border-radius: 5vh;
}
.cardHeader{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 6vw;
}
.cardUl{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 10vw;
    margin-top: 10vh;
    max-width: 90%;
    gap: 2vh;
}
.cardLi{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 4vw;
}
.price{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 9vw;
    margin-top: 10vh;
}
.button{
    background-color: #31D385;
    color:white;
    border: none;
    font-family: var(--font-family);
    font-weight: 700;
    border-bottom: .4vw solid #007F42;
    border-right: .1vw solid #007F42;
    border-left: .1vw solid #007F42;
    width: 70%;
    padding: 1vh 3vh;
    border-radius: 5vh;
    font-size: 6vw;
    margin-top: 5vh;
    transition: all .3s ease;
    cursor: pointer;
}
}