

.container{
    width: 100%;
    max-width: 100vw;
    height: auto;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;

}
.headerContainer{
    padding: .5vw 3vw;
    margin-top: 8vw;
    border-bottom: 4px solid #2B4EA7;
    border-right: 2px solid #2B4EA7;
    border-left: 2px solid #2B4EA7;
    border-radius: 5vw;
}
.header{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 2.5vw;
    opacity: .8;
    margin: 0 0 0 0;
}
.cardContainer{
    display: flex;
    gap: 8vw;
    align-items: center;
    justify-content: center;
    margin-top: 5vw;
    z-index: 2;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    padding: 0 5vw;
}
.card{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.img{
    height: 17vw;
}
.cardHeader{
    text-align: center;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1.3vw;
}

@media (max-width:768px) {
    .headerContainer{
        padding: 2vw 2vw;
        margin-top: 10vh;
        border-radius: 10vh;
        max-width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .header{
        font-size: 6vw;
        width: 70%;
        text-align: center;
        
    }
    .cardContainer{
        width: 100%;
        flex-direction: column;
        gap: 7vw;
        margin-top: 10vh
    }
    .card{
        width: 90%;
    
    }
    .img{
        height: auto;
        width: 70%;
    }
    .cardHeader{
        font-size: 5vw;
        width: 90%;
        font-weight: 700;
    }
}