html,body,#root,.App{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;

}
.App{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.popup-open {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}
.gradient{
  pointer-events: none;
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #006eff;
  background: linear-gradient(90deg,#006eff3f 0%, #006eff0d 5%,#ffffff00 8%,#ffffff00 92%, #006eff0d 95%, #006eff3f 100%);
  background: -webkit-linear-gradient(90deg,#006eff 0%, #ffffff00 5%, #ffffff00 95%, #006eff 100%);
  background: -moz-linear-gradient(90deg,#006eff 0%, #ffffff00 5%rgba(255, 255, 255, 0)ff 95%, #006eff 100%);
}
.bac1{
  pointer-events: none;
  position: absolute;
  top: 50vh;
  left: 0;
  width: 100%;
  z-index: 1;
  opacity: .5;
}
.bac2{
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  opacity: .5;
}
.bac3{
  pointer-events: none;
  position: absolute;
  top: -70vh;
  left: 0;
  width: 100%;
  z-index: 1;
  opacity: .5;
}
.bac4{
  pointer-events: none;
  position: absolute;
  bottom: 5vh;
  left: 0;
  width: 100%;
  z-index: 1;
  opacity: .5;
}
@media (max-width:768px) {
  .bac1,.bac2,.bac3,.bac4{
    display: none;
  }
}