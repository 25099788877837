.container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}


.bacgroundImg{
    width: 100%;
    position: absolute;
    left: 0;
    top: -20vh;
    z-index: 0;
}
.map{
    position: absolute;
    width: 4vw;
    left: 30%;
    top: 45%;
    z-index: 2;
}
.modalContainer{
    height: auto;
    width: auto;
    z-index: 2;
    position: relative;
    margin-top: 15vw;
    margin-left: 16vw;
}
.modalContainer2{
    height: auto;
    width: auto;
    z-index: 2;
    position: relative;
    margin-top: 3vw;
    margin-right: 16vw;
}
.modalWind{


    padding:5vw 3vw;
    background-color: #ffffff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow:  0px 15px 26px -10px rgba(0, 0, 0, 0.7);
    border-radius: 6vw 1vw 6vw 0  ;
}
.modalWind::before {
    content: ''; /* Обязательное свойство для псевдоэлементов */
    position: absolute; /* Позиционируем псевдоэлемент */
    top: -2px; /* Сдвигаем его на 1 пиксель вверх для перекрытия границы */
    left: -2px; /* Сдвигаем его на 1 пиксель влево для перекрытия границы */
    right: -2px; /* Растягиваем его на всю ширину */
    bottom: -2px; /* Растягиваем его на всю высоту */
    z-index: -1; /* Помещаем псевдоэлемент под основной контент */
    border-radius: 6vw 1vw 6vw 0; /* Закругляем края псевдоэлемента */
    background: linear-gradient(to bottom, #2D53B6, rgba(0, 0, 255, 0)); /* Задаем линейный градиент */
}
.modalWind2{


    padding: 3vw;
    background-color: #ffffff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow:  0px 15px 26px -10px rgba(0, 0, 0, 0.7);
    border-radius: 1vw 6vw 0 6vw  ;
}
.modalWind2::before {
    content: ''; /* Обязательное свойство для псевдоэлементов */
    position: absolute; /* Позиционируем псевдоэлемент */
    top: -2px; /* Сдвигаем его на 1 пиксель вверх для перекрытия границы */
    left: -2px; /* Сдвигаем его на 1 пиксель влево для перекрытия границы */
    right: -2px; /* Растягиваем его на всю ширину */
    bottom: -2px; /* Растягиваем его на всю высоту */
    z-index: -1; /* Помещаем псевдоэлемент под основной контент */
    border-radius: 1vw 6vw 0 6vw  ; /* Закругляем края псевдоэлемента */
    background: linear-gradient(to bottom, #2D53B6, rgba(0, 0, 255, 0)); /* Задаем линейный градиент */
}
.conteiner1{
    display: flex;
    justify-content: space-between;
}
.textContainer{
    display: flex;
    flex-direction: column;
}
.starContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.count{
    font-size: 1.4vw;
    margin-right: 1vw;
    font-family: var(--font-family);
    font-weight: 600;
}
.star{
    height: 1.5vw;
    transition: all .3s ease-in-out;
    cursor: pointer;
}
.star:hover{
    transform: scale(105%);
}
.Header{
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    font-weight: 700;
    margin-top: 3vw;
}
.Header span{
    font-size: 1.9vw;
}
.Header2{
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    font-weight: 700;
    margin-top: 3vw;
}
.Header2 span{
    font-size: 1.9vw;
}
.logoContainer{
    width: 30%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.logo{
    width: 100%;
}
.button{
    background-color: #31D385;
    color:white;
    font-family: var(--font-family);
    font-weight: 700;
    border-bottom: .4vw solid #007F42;
    border-right: .1vw solid #007F42;
    border-left: .1vw solid #007F42;
    width: 80%;
    padding: 1vw 0;
    border-radius: 5vw;
    font-size: 2vw;
    margin-top: 3vw;
    transition: all .3s ease;
    cursor: pointer;
}
.button:hover{
    transform: scale(102%);
    border-bottom: .4vw solid #01a656;
    border-right: .1vw solid #01a656;
    border-left: .1vw solid #01a656;
}


@media (max-width:768px){
    .container{
        background-image: url('./img/bacground.png');
        background-size: 450%; /* Растягиваем изображение на всю область контейнера */
        background-position: center; /* Выравниваем изображение по центру */
        padding: 25vh 0 10vh 0;
    }
    .bacgroundImg{
        display: none;
    }
    .modalContainer{
        height: auto;
        width: auto;
        z-index: 1;
        position: relative;
        margin-top: 0vh;
        margin-bottom: 15vh;
        margin-left: 0;
        width: 90%;
        box-sizing: border-box;

    }
    .modalContainer2{
        height: auto;
        width: auto;
        z-index: 1;
        position: relative;
        margin-top: 0vh;
        margin-bottom: 5vh;
        margin-right: 0;
        width: 90%;
        box-sizing: border-box;

    }
    .modalWind{
        padding: 6vh 4vw;
        background-color: #ffffff;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow:  0px 15px 26px -10px rgba(0, 0, 0, 0.7);
        border-radius: 6vw 1vw 6vw 0  ;
    }
    .modalWind::before {
        content: ''; /* Обязательное свойство для псевдоэлементов */
        position: absolute; /* Позиционируем псевдоэлемент */
        top: -2px; /* Сдвигаем его на 1 пиксель вверх для перекрытия границы */
        left: -2px; /* Сдвигаем его на 1 пиксель влево для перекрытия границы */
        right: -2px; /* Растягиваем его на всю ширину */
        bottom: -2px; /* Растягиваем его на всю высоту */
        z-index: -1; /* Помещаем псевдоэлемент под основной контент */
        border-radius: 6vw 1vw 6vw 0; /* Закругляем края псевдоэлемента */
        background: linear-gradient(to bottom, #2D53B6, rgba(0, 0, 255, 0)); /* Задаем линейный градиент */
    }
    .modalWind2{
        padding: 6vh 4vw;
        background-color: #ffffff;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow:  0px 15px 26px -10px rgba(0, 0, 0, 0.7);
        border-radius: 6vw 1vw 6vw 0  ;
    }
    .modalWind2::before {
        content: ''; /* Обязательное свойство для псевдоэлементов */
        position: absolute; /* Позиционируем псевдоэлемент */
        top: -2px; /* Сдвигаем его на 1 пиксель вверх для перекрытия границы */
        left: -2px; /* Сдвигаем его на 1 пиксель влево для перекрытия границы */
        right: -2px; /* Растягиваем его на всю ширину */
        bottom: -2px; /* Растягиваем его на всю высоту */
        z-index: -1; /* Помещаем псевдоэлемент под основной контент */
        border-radius: 6vw 1vw 6vw 0; /* Закругляем края псевдоэлемента */
        background: linear-gradient(to bottom, #2D53B6, rgba(0, 0, 255, 0)); /* Задаем линейный градиент */
    }
    .conteiner1{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .textContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
    }
    .starContainer{
        display: flex;
        align-items: center;
        justify-content:center;
    }
    .count{
        font-size: 4vw;
        margin-right: 1vw;
        font-family: var(--font-family);
        font-weight: 600;
    }
    .star{
        height: 3vh;
        transition: all .3s ease-in-out;
        cursor: pointer;
    }
    .star:hover{
        transform: scale(105%);
    }
    .Header{
        display: flex;
        flex-direction: column;
        font-family: var(--font-family);
        font-weight: 700;
        margin-top: 3vh;
        width: 90%;
        text-align: center;
    }
    .Header span, .Header2 span{
        font-size: 5vw;
    }
    .logoContainer{
        width: 90%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    .logo{
        width: 60%;
    }
    .button{
        background-color: #31D385;
        color:white;
        font-family: var(--font-family);
        font-weight: 700;
        border-bottom: .4vw solid #007F42;
        border-right: .1vw solid #007F42;
        border-left: .1vw solid #007F42;
        width: 80%;
        padding: 1vh 0;
        border-radius: 5vw;
        font-size: 4vw;
        margin-top: 3vh;
        transition: all .3s ease;
        cursor: pointer;
    }
    .button:hover{
        transform: scale(102%);
        border-bottom: .4vw solid #01a656;
        border-right: .1vw solid #01a656;
        border-left: .1vw solid #01a656;
    }
    .map{
        display: none;
    }
}